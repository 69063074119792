@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600&family=Lato&family=Open+Sans:wght@400;500;600&family=Poppins&display=swap');

.faq-img {
    height: 500px;
    width: 100%;
}

.faq-img img {
    height: 100%;
    width: 100%;
}

.faq {
    margin-bottom: 20px;
    transition: 0.3s all;
    border: 1px solid #d9e9ff;
    box-shadow: 0 3px 6px rgba(16, 55, 133, 0.16);
    border-radius: 2px;
}

.faq_active {
    margin-bottom: 30px;
}

.accordion-item {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    margin-bottom: 0.5em;
}

.accordion-button {
    min-height: 62px;
    padding: 10px 35px;
    background-color: #fff;
    color: #252526;
    font-size: 18px;
    font-weight: 600;
    border-radius: 3px;
    font-family: "Poppins", sans-serif;
    transition: 0.3s all;
    cursor: pointer;

}

.accordion-button:hover {
    background: #f6fbff;
}

.accordion-button.active {
    background: #f6fbff;
}

.accordion-body {
    color: #000;
    padding: 30px 35px;
    font-size: 15px;
    font-weight: 500;
}

.accordion-button:focus {
    z-index: 3;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: #f6fbff;
    box-shadow: none;
}
@media screen and (max-width: 480px) {
    .accordion-button {
        font-size: 17px;
    }
}