.navbar-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: #fff;
    z-index: 99;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.sticky {
    position: fixed;
    z-index: 99;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.sticky .navbar {
    padding: 10px 0;
}

.navbar {
    padding: 10px 0;
    border-radius: 5px;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.navbar-brand {
    padding: 0;
}

.navbar-brand img {
    height: 60px;
}

.navbar-toggler {
    padding: 0;
    outline: none;
    border: none;
}

.navbar-toggler .toggler-icon {
    width: 30px;
    height: 2px;
    background-color: #000;
    display: block;
    margin: 5px 0;
    position: relative;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.navbar-toggler.active .toggler-icon:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 7px;
}

.navbar-toggler.active .toggler-icon:nth-of-type(2) {
    opacity: 0;
}

.navbar-toggler.active .toggler-icon:nth-of-type(3) {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    top: -7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 5px 12px;
    }
}

@media (max-width: 767px) {
    .navbar-collapse {
        position: absolute;
        top: 102%;
        left: 0;
        width: 100%;
        background-color: #fff;
        z-index: 9;
        -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 5px 12px;
    }
}

.navbar-nav .nav-item {
    margin-right: 45px;
    position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .navbar-nav .nav-item {
        margin-right: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .nav-item {
        margin: 0;
    }
}

@media (max-width: 767px) {
    .navbar-nav .nav-item {
        margin: 0;
    }
}

.navbar-nav .nav-item a {
    font-size: 16px;
    font-weight: 400;
    color: #2E2E2E;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    padding: 10px 0;
    position: relative;
    font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .nav-item a {
        display: block;
        padding: 4px 0;
        color: #2E2E2E;
    }
}

@media (max-width: 767px) {
    .navbar-nav .nav-item a {
        display: block;
        padding: 4px 0;
        color: #2E2E2E;
    }
}


.navbar-nav .sub-nav-toggler {
    display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-nav .sub-nav-toggler {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        background: none;
        color: #2E2E2E;
        font-size: 18px;
        border: 0;
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 767px) {
    .navbar-nav .sub-nav-toggler {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        background: none;
        color: #2E2E2E;
        font-size: 18px;
        border: 0;
        width: 30px;
        height: 30px;
    }
}

.navbar-nav .sub-nav-toggler span {
    width: 8px;
    height: 8px;
    border-left: 1px solid #2E2E2E;
    border-bottom: 1px solid #2E2E2E;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: relative;
    top: -5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .navbar-btn {
        position: absolute;
        top: 50%;
        right: 50px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

@media (max-width: 767px) {
    .navbar-btn {
        position: absolute;
        top: 50%;
        right: 50px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

.navbar-btn .main-btn {
    height: 45px;
    line-height: 45px;
    background: -webkit-linear-gradient(left, #fe8464 0%, #fe6e9a 50%, #fe8464 100%);
    background: -o-linear-gradient(left, #fe8464 0%, #fe6e9a 50%, #fe8464 100%);
    background: linear-gradient(to right, #fe8464 0%, #fe6e9a 50%, #fe8464 100%);
    background-size: 200%;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar-btn .main-btn:hover {
    color: #fff;
    background-position: right center;
}

.sticky .navbar-toggler .toggler-icon {
    background-color: #2E2E2E;
}

.sticky .navbar-nav .nav-item a {
    color: #2E2E2E;
}

.sticky .navbar-nav .nav-item.active>a,
.sticky .navbar-nav .nav-item:hover>a {
    color: #361CC1;
}

button:focus:not(:focus-visible) {
    outline: 0;
    border: none;
}

.navi {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width:991px) {
    .navi {
        justify-content: flex-start;
    }
}

/*===== HEADER HERO =====*/
#home {
    position: relative;
    overflow: hidden;
}

.header-hero {
    background-image: url(../images/banner-bg.svg);
    position: relative;
    z-index: 5;
    background-position: bottom center;
}

#particles-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -33;
}

.header-hero-content {
    padding-top: 180px;
}

@media (max-width: 767px) {
    .header-hero-content {
        padding-top: 100px;
    }
}

.header-hero-content .header-sub-title {
    font-size: 38px;
    font-weight: 300;
    color: #fff;
}

@media (max-width: 767px) {
    .header-hero-content .header-sub-title {
        font-size: 24px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-hero-content .header-sub-title {
        font-size: 30px;
    }
}

.header-hero-content .header-title {
    font-size: 40px;
    font-weight: 700;
    color: #fff;
}

@media (max-width: 767px) {
    .header-hero-content .header-title {
        font-size: 30px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-hero-content .header-title {
        font-size: 30px;
    }
}

.header-hero-content .text {
    color: #fff;
    margin-top: 30px;
}

.header-hero-content .main-btn {
    margin-top: 40px;
}

.form {
    display: flex;
    align-items: center;
    justify-content: center;
}

.banner_form {
    max-width: 800px;
    width: 100%;
    padding: 2rem;
    border-radius: 1rem;
    margin-top: 3rem;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}


@media (max-width: 767px) {
    .header-hero-image {
        padding-top: 30px;
    }
}

.PhoneInputInput{
    outline: none!important;
    border: none!important;
}