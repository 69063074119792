.bg-d{
    background-color: var(--light-3);
}

.services_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 30px 50px;
}

.single-services {
    background-color: #fff;
    -webkit-box-shadow: 0px 5px 30px 0px rgba(167, 167, 167, 0.16);
    -moz-box-shadow: 0px 5px 30px 0px rgba(167, 167, 167, 0.16);
    box-shadow: 0px 5px 30px 0px rgba(167, 167, 167, 0.16);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    padding: 50px 30px;
    border: 2px solid transparent;
    border-radius: 8px;
}

@media (max-width: 991px) {
    .services_container {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}

@media (max-width: 767px) {
    .services_container {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 10px;
    }

}

.single-services .services-icon {
    display: inline-block;
    position: relative;
}

.single-services .services-icon img {
    -webkit-transition: all 0.4s ease-out 0s;
    -moz-transition: all 0.4s ease-out 0s;
    -ms-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.single-services .services-icon .shape-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 80px;
}



.single-services .services-content .services-title  {
    font-size: 26px;
    font-weight: 700;
    color: #2E2E2E;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-services .services-content .services-title  {
        font-size: 24px;
    }
}

@media (max-width: 767px) {
    .single-services .services-content .services-title {
        font-size: 20px;
    }
}



.single-services .services-content .text {
    margin-top: 30px;
}

@media (max-width: 767px) {
    .single-services .services-content .text {
        margin-top: 20px;
    }
}

.single-services .services-content .more {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 400;
    color: #2E2E2E;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.single-services .services-content .more i {
    margin-left: 10px;
}

.single-services .services-content .more:hover {
    color: #361CC1;
}

.single-services:hover {
    border-color: #FE7A7B;
}

.single-services:hover .services-icon .shape {
    -webkit-transform: rotate(25deg);
    -moz-transform: rotate(25deg);
    -ms-transform: rotate(25deg);
    -o-transform: rotate(25deg);
    transform: rotate(25deg);
}