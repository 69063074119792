.single-testimonial {
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(167, 167, 167, 0.16);
    -moz-box-shadow: 0px 0px 30px 0px rgba(167, 167, 167, 0.16);
    box-shadow: 0px 0px 30px 0px rgba(167, 167, 167, 0.16);
    overflow: hidden;
    border-radius: 10px;
    padding: 25px 30px 25px;
    border-radius: 10px;
    border: 2px solid transparent;
    margin:  30px 10px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

@media (max-width: 767px) {
    .single-testimonial {
        padding: 20px;
    }
}

 .quota .quote-alt-left {
    font-size: 36px;
    color: #119BD2;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.single-testimonial .testimonial-review .star ul li {
    display: inline-block;
    font-size: 16px;
    color: #FE806D;
}

.single-testimonial .testimonial-text {
    margin-top: 25px;
}

.single-testimonial .testimonial-author {
    margin-top: 30px;
}

.single-testimonial .testimonial-author .author-image {
    position: relative;
}

.single-testimonial .testimonial-author .author-image .author {
    position: absolute;
    top: 17px;
    left: 17px;
    width: 80px;
    height: 80px;
}

.single-testimonial .testimonial-author .author-content .holder-name {
    font-size: 18px;
    font-weight: 700;
    color: #2E2E2E;
}

.single-testimonial .testimonial-author .author-content .text {
    font-size: 15px;
}

.single-testimonial:hover {
    border-color: #FE7A7B;
}

.single-testimonial:hover .testimonial-review .quota i {
    color: #FE7A7B;
}

.slick-center .single-testimonial {
    border-color: #FE7A7B;
}

.slick-center .single-testimonial .testimonial-review .quota i {
    color: #FE7A7B;
}

.testimonial-active .slick-dots {
    margin: 0 auto;
}

.testimonial-active .slick-dots li {
    display: inline-block;
    margin: 0 4px;
}

.testimonial-active .slick-dots li button {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: linear-gradient(180deg, #fe8464 0%, #fe6e9a 100%);
    border: 0;
    font-size: 0;
    opacity: 0.5;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.testimonial-active .slick-dots li.slick-active button {
    opacity: 1;
    width: 15px;
    height: 15px;
}