.thank {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.thankyou_img {
    max-height: 500px;
}

.thankyou_img img {
    height: 100%;
    width: 100%;
}