.trusted-block {
    text-align: center;
    padding: 35px;
    background-color: #fff;
    border-radius: 6px;
    position: relative;
    border: 2px solid transparent;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
@media only screen and (min-width:480px) and (max-width: 767px) {
    .trusted-logo .googleImg {       
        width: 50%;
    }
}
.trusted-logo .googleImg {       
    width: 50%;
}
.trusted-logo .sitejabber{
    width: 90%;
}
.trusted-block:hover {
    border-color: red;
}

.rating,
.review-count {
    font-size: 18px;
    color: #766d6d;
    font-weight: 600;
    margin: .7rem 0;
}

.review-count {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
}

.rate_star {
    color: #ffb700;
}

.trusted-logo img {
    margin-top: 10px;
    height: 60px;
    width: 70%;
}

@media screen and (max-width : 991px) {
    .trusted-block {
        margin: 1rem;
    }
}

@media only screen and (min-width:767px) and (max-width: 991px)  {
    .trusted-logo img {
        width: 90%;
    }
    .trusted-logo .googleImg{
        width: 60%;
    }
}