.subject_img {
    position: relative;
}

.sub_shape {
    height: 100%;
    width: 100%;
    z-index: 1;
}

.subject_img .sub_img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.marg-50 {
    margin-top: 80px;
}

.single-sub {
    border-radius: 3px;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

}

.single-sub-head {
    padding: 0.5rem 2rem 0.5rem 1rem;
    font-size: 17px;
    min-height: 3.5rem;
    line-height: 1.25rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    color: #000;
    text-transform: uppercase;
    cursor: pointer;
}

.single-sub-head::after {
    content: "+";
    width: 14px;
    height: 14px;
    font-size: 26px;
}

.single-sub-head.active::after {
    content: "-";
    width: 14px;
    height: 14px;
    font-size: 26px;
}

.single-sub-body {
    max-height: 0;
    overflow: hidden;
    line-height: 150%;
    opacity: 0.8;
    transition: all .5s;
}

.single-sub-body.show {
    height: auto;
    max-height: 555px;
}

.single-sub-body-content {
    padding: 0 20px 20px;
}

@media screen and (max-width: 991px) {
    .marg-50 {
        margin-top: 50px;
    }

    .subject_img img {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    .marg-50 {
        margin-top: 20px;
    }

}