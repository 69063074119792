.about-five {
    padding-top: 60px;
    padding-bottom: 90px
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-five {
        padding-top: 100px;
        padding-bottom: 70px
    }
}

@media(max-width:767px) {
    .about-five {
        padding-top: 80px;
        padding-bottom: 60px
    }
}

.about-five-content {
    padding-left: 50px
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-five-content {
        padding-left: 0
    }
}

@media(max-width:767px) {
    .about-five-content {
        padding-left: 0
    }
}

.about-five-content .small-title {
    position: relative;
    padding-left: 30px
}

.about-five-content .small-title::before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    background-color: var(--primary);
    height: 2px;
    width: 20px;
    margin-top: -1px
}

.about-five-content .main-title {
    font-size: 28px;
    margin-top: 20px
}

.about-five-content .about-five-tab {
    margin-top: 40px
}

.about-five-content .about-five-tab nav {
    border: none;
    background-color: var(--light-1);
    padding: 15px;
    border-radius: 5px
}

.about-five-content .about-five-tab nav .nav-tabs {
    border: none
}

.about-five-content .about-five-tab nav .nav-link {
    border: none;
    color: var(--dark-1);
    font-weight: 600;
    text-align: center;
    padding: 0;
    margin-right: 20px;
    position: relative;
    background-color: var(--white);
    padding: 10px 18px;
    border-radius: 4px;
    text-transform: capitalize
}

@media(max-width:767px) {
    .about-five-content .about-five-tab nav .nav-link {
        margin: 0;
        margin-bottom: 10px;
        width: 100%
    }

    .about-five-content .about-five-tab nav .nav-link:last-child {
        margin: 0
    }
}

.about-five-content .about-five-tab nav .nav-link:hover {
    color: var(--primary)
}

.about-five-content .about-five-tab nav .nav-link.active {
    background-color: var(--primary);
    color: var(--white)
}

.about-five-content .about-five-tab nav .nav-link:last-child {
    margin-right: 0
}

.about-five-content .about-five-tab .tab-content {
    border: none;
    padding-top: 30px
}

.about-five-content .about-five-tab .tab-content p {
    margin-bottom: 20px
}

.about-five-content .about-five-tab .tab-content p:last-child {
    margin: 0
}

.about-image-five {
    padding-left: 60px;
    position: relative;
    border-radius: 10px;
    z-index: 2
}
.about-image-five img{
    border-radius: 10px;
}
@media only screen and (min-width:768px) and (max-width:991px) {
    .about-image-five {
        margin-bottom: 70px;
        padding-left: 30px
    }
}

@media(max-width:767px) {
    .about-image-five {
        margin-bottom: 60px;
        padding-left: 0
    }
}

.about-image-five .shape {
    position: absolute;
    left: 30px;
    top: -30px;
    z-index: -1
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-image-five .shape {
        left: 0
    }
}

@media only screen and (min-width:768px) and (max-width:991px) {
    .about-image-five::before {
        right: -15px;
        bottom: -15px
    }
}

@media(max-width:767px) {
    .about-image-five::before {
        display: none
    }
}

.about-image-five img {
    width: 100%;
    z-index: 2
}